import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebase/firebase-config';
import { collection, query, onSnapshot, orderBy, getDoc, doc, deleteDoc, where } from 'firebase/firestore';
import { ListGroup, Badge, Button, Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import './QnAList.css';
import QnAQuestion from '../../models/QnAQuestion';
import { useUser } from '../../context/UserContext';

function QnAList({ onSelectQuestion }) {
  const [questions, setQuestions] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});
  const { user, loading } = useUser();  // Access user and loading states

  // Fetch questions based on user role and unit
  useEffect(() => {
    if (loading || !user) return; // Don't run if still loading or user data isn't available

    let q;

    // Admin can see all questions
    if (user.role === 'admin') {
      q = query(collection(db, "questions"), orderBy("createdAt", "desc"));
    } 
    // Local-admin and users see "Splošno" and their own unit
    else if (user.role === 'local-admin' || user.role === 'user') {
      q = query(
        collection(db, "questions"),
        where('unit', 'in', ['general', user.unit]),  // Fetch both 'general' and the user's unit
        orderBy("createdAt", "desc")
      );
    }

    // Fetch the questions
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const questionsList = querySnapshot.docs.map(doc => QnAQuestion.fromFirestore(doc));
      setQuestions(questionsList);
    });

    return () => unsubscribe();  // Clean up the listener
  }, [user, loading]);

  // Fetch unread counts for each question
  useEffect(() => {
    if (loading || !user) return;

    const fetchUnreadCounts = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data().lastRead?.qna || {};

      const newUnreadCounts = {};
      const q = query(collection(db, 'questions'));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const questionId = doc.id;
          const messagesCollectionRef = collection(db, 'questions', questionId, 'messages');
          const lastReadTimestamp = lastReadTimestamps[questionId] || 0;
          const messageQuery = query(messagesCollectionRef, where('createdAt', '>', lastReadTimestamp));

          onSnapshot(messageQuery, (messageSnapshot) => {
            const unreadCount = messageSnapshot.docs.filter(doc => doc.data().uid !== auth.currentUser.uid).length;
            newUnreadCounts[questionId] = unreadCount;
            setUnreadCounts({ ...newUnreadCounts });
          });
        });
      });

      return () => unsubscribe();
    };

    fetchUnreadCounts();
  }, [loading, user]);

  // Admin delete functionality
  const handleDelete = async (questionId) => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      await deleteDoc(doc(db, 'questions', questionId));  // Delete the question from Firestore
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!questions.length) {
    return <div>No questions available</div>;
  }

  return (
    <ListGroup className="qna-list">
      {questions.map((question) => (
        <ListGroup.Item key={question.id} className="qna-list-item">
          <Row>
            {/* Display question details */}
            <Col md={12} onClick={() => onSelectQuestion(question)} className="d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div className="fw-bold">{question.title}</div>
                <small>Created by {question.displayName}</small>
              </div>

              {/* Unit Indicator */}
              <Badge bg="info" className="ms-2">{question.unit === 'general' ? 'Splošno' : question.unitName}</Badge>

              <small className="text-muted text-end ms-2">{question.createdAt.toLocaleString()}</small>
              {unreadCounts[question.id] > 0 && (
                <Badge bg="danger" className="ms-2" pill>{unreadCounts[question.id]}</Badge>
              )}
            </Col>
          </Row>

          {/* Display delete button for admins in a new row */}
          {user.role === 'admin' && (
            <Row>
              <Col md={2} className="d-flex ms-2 my-2">
                <Button variant="outline-danger" size="sm" onClick={() => handleDelete(question.id)}>
                  <FaTrash /> Delete
                </Button>
              </Col>
            </Row>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default QnAList;
