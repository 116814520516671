import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import NoticeCreate from './NoticeCreate';
import NoticeList from './NoticeList';
import NoticeDetail from './NoticeDetail';
import { useUser } from '../../context/UserContext';
import './Notices.css';

function Notices() {
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [showCreateNotice, setShowCreateNotice] = useState(false);

  const { user } = useUser();

  const handleSelectNotice = (notice) => {
    setSelectedNotice(notice);
    setShowCreateNotice(false);
  };

  const handleBack = () => {
    setSelectedNotice(null);
    setShowCreateNotice(false);
  };

  const toggleCreateNoticeForm = () => {
    setShowCreateNotice(!showCreateNotice);
    setSelectedNotice(null);
  };

  const handleNoticeCreated = () => {
    setShowCreateNotice(false);
    setSelectedNotice(null);
  };

  return (
    <div className="full-bg">
      <Container className="notices-container mt-3">
        <Row className="justify-content-between align-items-center mb-3">
          <Col>
            <h1 className="notices-title">Obvestila</h1>
          </Col>
          <Col className="text-end">
            {!selectedNotice && (user.role === 'admin' || user.role === 'local-admin') && (
              <Button variant={showCreateNotice ? "secondary" : "danger"} onClick={toggleCreateNoticeForm}>
                {showCreateNotice ? 'Nazaj na izbiro' : 'Ustvari novico'}
              </Button>
            )}
            {selectedNotice && (
              <Button variant="secondary" onClick={handleBack}>Back to List</Button>
            )}
          </Col>
        </Row>
        {!selectedNotice ? (
          <>
            {showCreateNotice ? (
              <NoticeCreate onNoticeCreated={handleNoticeCreated} />
            ) : (
              <NoticeList onSelectNotice={handleSelectNotice} isAdmin={user && user.role === "admin"} />
            )}
          </>
        ) : (
          <NoticeDetail notice={selectedNotice} />
        )}
      </Container>
    </div>
  );
}

export default Notices;
