import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from '../../firebase/firebase-config';
import { collection, query, orderBy, onSnapshot, doc, getDoc, limit, startAfter, getDocs, deleteDoc } from 'firebase/firestore';
import { ListGroup, Badge, Button, Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import './BenefitList.css';
import { useUser } from '../../context/UserContext';

const BENEFITS_LIMIT = 20;

function BenefitList({ onSelectBenefit, isAdmin }) {
    const [benefits, setBenefits] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const benefitsEndRef = useRef(null);
    const benefitsContainerRef = useRef(null);
    const [unreadBenefits, setUnreadBenefits] = useState([]);
    const [unitNames, setUnitNames] = useState({});
    const { user } = useUser();

    useEffect(() => {
        const fetchUnits = async () => {
            const unitsQuerySnapshot = await getDocs(collection(db, 'units'));
            const unitsData = unitsQuerySnapshot.docs.reduce((acc, unitDoc) => {
                acc[unitDoc.id] = unitDoc.data().unitName;
                return acc;
            }, {});
            setUnitNames(unitsData);
        };

        fetchUnits();
    }, []);

    useEffect(() => {
        const fetchUnreadBenefits = async () => {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            const userDocSnap = await getDoc(userDocRef);
            const lastReadTimestamps = userDocSnap.data().lastRead?.benefits || {};

            const newUnreadBenefits = [];
            const q = query(collection(db, 'benefits'), orderBy('createdAt', 'desc'));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const benefitId = doc.id;
                    const createdAt = doc.data().createdAt;
                    if (!lastReadTimestamps[benefitId] || lastReadTimestamps[benefitId].toMillis() < createdAt.toMillis()) {
                        newUnreadBenefits.push(benefitId);
                    }
                });
                setUnreadBenefits(newUnreadBenefits);
            });

            return () => unsubscribe();
        };

        fetchUnreadBenefits();
    }, []);

    useEffect(() => {
        const q = query(collection(db, "benefits"), orderBy("createdAt", "desc"), limit(BENEFITS_LIMIT));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const benefitsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                createdAt: doc.data().createdAt.toMillis()
            }));
            setBenefits(benefitsData);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        });

        return () => unsubscribe();
    }, []);

    const loadMoreBenefits = async () => {
        if (!lastVisible || loadingMore) return;

        setLoadingMore(true);
        const q = query(collection(db, "benefits"), orderBy("createdAt", "desc"), startAfter(lastVisible), limit(BENEFITS_LIMIT));
        const querySnapshot = await getDocs(q);
        const moreBenefits = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt.toMillis()
        }));

        setBenefits(prevBenefits => [...prevBenefits, ...moreBenefits]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setLoadingMore(false);
    };

    const handleScroll = () => {
        if (benefitsContainerRef.current.scrollTop + benefitsContainerRef.current.clientHeight >= benefitsContainerRef.current.scrollHeight) {
            loadMoreBenefits();
        }
    };

    const handleDelete = async (benefitId) => {
        if (window.confirm('Are you sure you want to delete this benefit?')) {
            await deleteDoc(doc(db, 'benefits', benefitId));  // Delete the benefit from Firestore
        }
    };

    return (
        <div className="benefit-list-container" ref={benefitsContainerRef} onScroll={handleScroll}>
            <ListGroup className="benefit-list">
                {benefits.map((benefit) => (
                    <ListGroup.Item key={benefit.id} className="benefit-list-item">
                        <Row>
                            <Col md={12} onClick={() => onSelectBenefit(benefit)} className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{benefit.title}</div>
                                    <small>Ustvaril {benefit.displayName || 'Anonymous'}</small>
                                </div>
                                <Badge bg="info" className="ms-2">{benefit.unit === 'general' ? 'Splošno' : benefit.unitName}</Badge>
                                <small className="text-muted">{new Date(benefit.createdAt).toLocaleString()}</small>
                                {unreadBenefits.includes(benefit.id) && (
                                    <Badge bg="danger" className="ms-1" pill>&nbsp;</Badge>
                                )}
                            </Col>
                        </Row>

                        {isAdmin && (
                            <Row>
                                <Col md={2} className="d-flex ms-2 my-2">
                                    <Button variant="outline-danger" size="sm" onClick={() => handleDelete(benefit.id)}>
                                        <FaTrash /> Izbriši
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </ListGroup.Item>
                ))}
                <div ref={benefitsEndRef} />
            </ListGroup>
        </div>
    );
}

export default BenefitList;
