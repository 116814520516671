import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_API_KEY_DEV : process.env.REACT_APP_FIREBASE_API_KEY_PROD,
  authDomain: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
  projectId: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV : process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
  storageBucket: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
  messagingSenderId: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV : process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PROD,
  appId: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_APP_ID_DEV : process.env.REACT_APP_FIREBASE_APP_ID_PROD,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

