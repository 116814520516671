import React from 'react';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';  // Import the trash icon
import { auth, db } from '../../firebase/firebase-config';
import { deleteDoc, doc } from 'firebase/firestore';
import './Message.css';

function Message({ message, isAdmin, onDelete }) {
    const { text, displayName, email, createdAt, uid } = message;
    const messageTime = createdAt ? new Date(createdAt).toLocaleString() : '';
    const isCurrentUser = uid === auth.currentUser?.uid;

    return (
        <div className={`message-wrapper ${isCurrentUser ? 'right' : 'left'} d-flex align-items-center`}>
            {/* Message Bubble */}
            <div className={`message ${isCurrentUser ? 'text-end' : 'text-start'}`}>
                <strong>{displayName || email}</strong>
                <div>{text}</div>
                <small className="text-muted">{messageTime}</small>
            </div>

            {/* Delete Button */}
            {isAdmin && (
                <Button
                    variant="outline-danger"
                    size="sm"
                    className="ms-2"
                    onClick={onDelete}  // Call the onDelete handler passed from parent component
                    style={{ padding: '0.25rem', borderRadius: '50%' }}
                >
                    <FaTrash />
                </Button>
            )}
        </div>
    );
}

export default Message;
