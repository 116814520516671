import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase/firebase-config';  // Import deleteDoc
import { collection, query, orderBy, doc, deleteDoc, getDoc, onSnapshot, getDocs, serverTimestamp, updateDoc, limit, startAfter } from 'firebase/firestore';
import { Card } from 'react-bootstrap';
import Message from '../common/Message';
import './QnAChat.css';
import QnAMessageForm from './QnAMessageForm';
import QnAMessage from '../../models/QnAMessage';
import { useUser } from '../../context/UserContext';

const MESSAGES_LIMIT = 20;

function QnAChat({ questionId, questionTitle }) {
    const [messages, setMessages] = useState([]);
    const [lastReadTimestamp, setLastReadTimestamp] = useState(null);
    const [lastVisible, setLastVisible] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const messagesEndRef = useRef(null);
    const unreadDividerRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const initialLoadRef = useRef(true);

    const { user } = useUser();
    const isAdmin = user?.role === 'admin';
    const messagesCollectionRef = collection(db, "questions", questionId, "messages");

    useEffect(() => {
        if (!questionId || !user) return;

        const fetchLastReadTimestamp = async () => {
            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnap = await getDoc(userDocRef);
            const lastRead = userDocSnap.data().lastRead?.qna || {};
            setLastReadTimestamp(lastRead[questionId]?.toMillis() || 0);
        };

        fetchLastReadTimestamp();

        const q = query(messagesCollectionRef, orderBy("createdAt", "desc"), limit(MESSAGES_LIMIT));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const msgs = querySnapshot.docs.map(doc => QnAMessage.fromFirestore(doc));
            setMessages(msgs.reverse());
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (initialLoadRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
                initialLoadRef.current = false;
            }
        });

        return () => unsubscribe();
    }, [questionId, user]);

    const loadMoreMessages = async () => {
        if (!lastVisible || loadingMore) return;

        setLoadingMore(true);
        const previousScrollHeight = messagesContainerRef.current.scrollHeight;

        const q = query(messagesCollectionRef, orderBy("createdAt", "desc"), startAfter(lastVisible), limit(MESSAGES_LIMIT));
        const querySnapshot = await getDocs(q);
        const moreMessages = querySnapshot.docs.map(doc => QnAMessage.fromFirestore(doc));

        setMessages(prevMessages => [...moreMessages.reverse(), ...prevMessages]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

        setTimeout(() => {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - previousScrollHeight;
            setLoadingMore(false);
        }, 0);
    };

    const handleScroll = () => {
        if (messagesContainerRef.current.scrollTop === 0) {
            loadMoreMessages();
        }
    };

    useEffect(() => {
        if (messages.length > 0) {
            if (unreadDividerRef.current) {
                unreadDividerRef.current.scrollIntoView({ behavior: 'auto' });
            } else {
                messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
            }
            setTimeout(() => {
                markAsRead();
            }, 500);
        }
    }, [messages]);

    const markAsRead = async () => {
        if (!user) return;
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, {
            [`lastRead.qna.${questionId}`]: serverTimestamp()
        });
    };

    // Deletion logic for QnA messages
    const handleDeleteMessage = async (messageId) => {
        if (window.confirm('Are you sure you want to delete this message?')) {
            await deleteDoc(doc(db, "questions", questionId, "messages", messageId));
        }
    };

    let dividerShown = false;

    return (
        <Card className="d-flex flex-column qna-chat-card">
            <Card.Header as="h5" className="qna-chat-card-header">{questionTitle}</Card.Header>
            <Card.Body className="flex-grow-1 overflow-auto qna-chat-card-body" ref={messagesContainerRef} onScroll={handleScroll}>
                {messages.map((msg, index) => {
                    const showDivider = !dividerShown && msg.createdAt && msg.createdAt > lastReadTimestamp && msg.uid !== user.uid;
                    if (showDivider) {
                        dividerShown = true;
                    }
                    return (
                        <React.Fragment key={msg.id}>
                            {showDivider && <div className="unread-divider" ref={unreadDividerRef}>Neprebrana sporočila</div>}
                            <Message message={msg} isAdmin={isAdmin} onDelete={() => handleDeleteMessage(msg.id)} />  {/* Pass onDelete handler */}
                        </React.Fragment>
                    );
                })}
                <div ref={messagesEndRef} />
            </Card.Body>
            <Card.Footer className="p-3 qna-chat-card-footer">
                <QnAMessageForm messagesCollectionRef={messagesCollectionRef} />
            </Card.Footer>
        </Card>
    );
}

export default QnAChat;
