class QnAQuestion {
  constructor({ id, title, uid, displayName, createdAt, unit, unitName }) {
    this.id = id || null;
    this.title = title || "";
    this.uid = uid || null;
    this.displayName = displayName || "Anonymous";
    this.createdAt = createdAt || new Date();
    this.unit = unit || "general";
    this.unitName = unitName || "Splošno";  // Store unitName as well
  }

  // Convert to Firestore format
  toFirestore() {
    return {
      title: this.title,
      uid: this.uid,
      displayName: this.displayName,
      createdAt: this.createdAt,
      unit: this.unit,
      unitName: this.unitName  // Include unitName in Firestore data
    };
  }

  // Create instance from Firestore document
  static fromFirestore(snapshot) {
    const data = snapshot.data();
    return new QnAQuestion({
      id: snapshot.id,
      title: data.title,
      uid: data.uid,
      displayName: data.displayName,
      createdAt: data.createdAt ? data.createdAt.toDate() : null,
      unit: data.unit || "general",
      unitName: data.unitName || "Splošno"  // Get the unitName from Firestore data
    });
  }
}

export default QnAQuestion;
