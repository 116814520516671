import React, { useState, Suspense, lazy } from 'react';
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { useUser } from '../../context/UserContext';
import './Documents.css';

const DocumentCreate = lazy(() => import('./DocumentCreate'));
const DocumentList = lazy(() => import('./DocumentList'));
const DocumentDetail = lazy(() => import('./DocumentDetail'));

function Documents() {
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showCreateDocument, setShowCreateDocument] = useState(false);

    const { user } = useUser();
    const canCreateDocument = user?.role === 'admin' || user?.role === 'local-admin';

    const handleSelectDocument = (document) => {
        setSelectedDocument(document);
        setShowCreateDocument(false);
    };

    const handleBack = () => {
        setSelectedDocument(null);
        setShowCreateDocument(false);
    };

    const toggleCreateDocumentForm = () => {
        setShowCreateDocument(!showCreateDocument);
        setSelectedDocument(null);
    };

    const handleDocumentCreated = () => {
        setShowCreateDocument(false);
        setSelectedDocument(null);
    };

    return (
        <div className="full-bg">
            <Container className="documents-container mt-3">
                <Row className="justify-content-between align-items-center mb-3">
                    <Col>
                        <h1 className="documents-title">Documents</h1>
                    </Col>
                    <Col className="text-end">
                        {canCreateDocument && !selectedDocument && (
                            <Button variant={showCreateDocument ? "secondary" : "danger"} onClick={toggleCreateDocumentForm}>
                                {showCreateDocument ? 'Back to List' : 'Upload Document'}
                            </Button>
                        )}
                        {selectedDocument && (
                            <Button variant="secondary" onClick={handleBack}>Back to List</Button>
                        )}
                    </Col>
                </Row>
                <Suspense fallback={<Spinner animation="border" />}>
                    {!selectedDocument ? (
                        showCreateDocument ? (
                            <DocumentCreate onDocumentCreated={handleDocumentCreated} />
                        ) : (
                            <DocumentList onSelectDocument={handleSelectDocument} isAdmin={canCreateDocument} />
                        )
                    ) : (
                        <DocumentDetail document={selectedDocument} />
                    )}
                </Suspense>
            </Container>
        </div>
    );
}

export default Documents;
