import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Chat from '../components/chat/Chat';
import QnA from '../components/qna/QnA';
import Polls from '../components/polls/Polls';
import Questionnaire from '../components/questionnaire/Questionnaire';
import Profile from '../components/profile/Profile';
import Login from '../components/login/Login';
import Dashboard from '../components/dashboard/Dashboard';
import UserManagement from '../components/userManagement/UserManagement';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChatRoom from '../components/chat/ChatRoom';
import Notices from '../components/notices/Notices';
import Gallery from '../components/gallery/Gallery';
import Documents from '../components/documents/Documents';
import Sindikalist from '../components/sindikalist/Sindikalist';
import Article from '../components/articles/Article';
import Benefits from '../components/benefits/Benefits';
import { UserProvider, useUser } from '../context/UserContext';  // Import UserContext and UserProvider

function AppRoutes() {
  const { user, profileComplete, loading } = useUser();  // Use the user and loading state from the context

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {user ? (
        <Route path="/" element={<Dashboard />}>
          {profileComplete ? (
            <>
              <Route index element={<Navigate to="chat" />} />
              <Route path="chat" element={<Chat />} />
              <Route path="chat/:unitId" element={<ChatRoom />} />
              <Route path="qna" element={<QnA />} />
              <Route path="polls" element={<Polls />} />
              <Route path="notices" element={<Notices />} />
              <Route path="questionnaire" element={<Questionnaire />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="documents" element={<Documents />} />
              <Route path="sindikalist" element={<Sindikalist />} />
              <Route path="article" element={<Article />} />
              <Route path="profile" element={<Profile />} />
              <Route path="benefits" element={<Benefits />} />
              <Route path="user-management" element={<UserManagement />} />
              <Route path="*" element={<Navigate to="/chat" />} />
            </>
          ) : (
            <>
              <Route path="profile" element={<Profile />} />
              <Route path="*" element={<Navigate to="/profile" />} />
            </>
          )}
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
    </Routes>
  );
}

function App() {
  return (
    <UserProvider>  {/* Wrap the entire app with UserProvider */}
      <Router>
        <AppRoutes />
      </Router>
    </UserProvider>
  );
}

export default App;
