import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { auth, db } from '../../firebase/firebase-config';
import { ListGroup, Container, Badge } from 'react-bootstrap';
import './UnitSelector.css';

function UnitSelector({ onSelectUnit }) {
    const [units, setUnits] = useState([]);
    const [userUnit, setUserUnit] = useState('');
    const [userRole, setUserRole] = useState('');
    const [unreadCounts, setUnreadCounts] = useState({});

    // Hardcoded chat rooms
    const hardcodedRooms = [
        { id: 'general', unitName: 'Splošno', accessibleToAll: true },
        { id: 'conference', unitName: 'Konferenca sindikatov zavarovalništva Slovenije', accessibleToRoles: ['admin', 'local-admin'] }
    ];

    useEffect(() => {
        const fetchProfile = async () => {
            const docRef = doc(db, "users", auth.currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setUserUnit(docSnap.data().unit);
                setUserRole(docSnap.data().role || 'user');
            }
        };

        const fetchUnits = async () => {
            const unitsCollectionRef = collection(db, "units");
            const unitsSnapshot = await getDocs(unitsCollectionRef);
            const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUnits(unitsList);
        };

        fetchProfile();
        fetchUnits();
    }, []);

    useEffect(() => {
        const fetchUnreadCounts = async () => {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            const userDocSnap = await getDoc(userDocRef);
            const lastReadTimestamps = userDocSnap.data().lastRead?.chat || {};

            const unsubscribes = [];

            Object.keys(lastReadTimestamps).forEach(unitId => {
                const q = query(
                    collection(db, 'messages'),
                    where('unit', '==', unitId),
                    where('createdAt', '>', lastReadTimestamps[unitId]),
                    orderBy('createdAt')
                );

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const newUnreadCounts = {};
                    querySnapshot.forEach((doc) => {
                        const message = doc.data();
                        if (message.unit in newUnreadCounts) {
                            newUnreadCounts[message.unit] += 1;
                        } else {
                            newUnreadCounts[message.unit] = 1;
                        }
                    });
                    setUnreadCounts(prevCounts => ({
                        ...prevCounts,
                        ...newUnreadCounts
                    }));
                });

                unsubscribes.push(unsubscribe);
            });

            return () => unsubscribes.forEach(unsubscribe => unsubscribe());
        };

        fetchUnreadCounts();
    }, [userUnit, userRole]);

    const handleUnitClick = (unit) => {
        // Allow access based on the role and unit's access permissions
        if (
            unit.accessibleToAll || // Accessible to all users
            userRole === 'admin' || // Admin can access everything
            (unit.accessibleToRoles && unit.accessibleToRoles.includes(userRole)) || // Check if role is in allowed roles
            unit.id === userUnit // User can access their own unit
        ) {
            onSelectUnit(unit); // Select the unit (open the chat)
        }
    };

    const renderUnitList = () => {
        const allUnits = [
            ...hardcodedRooms,
            ...units // Add dynamic units from the database
        ];

        return allUnits
            .filter(unit => {
                // Filter units to only show ones the user has access to
                return unit.accessibleToAll ||
                    userRole === 'admin' ||
                    (unit.accessibleToRoles && unit.accessibleToRoles.includes(userRole)) ||
                    unit.id === userUnit;
            })
            .map(unit => (
                <ListGroup.Item
                    key={unit.id}
                    action
                    onClick={() => handleUnitClick(unit)}
                    className="d-flex justify-content-between align-items-start unit-list-item"
                    style={{ cursor: 'pointer' }}
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{unit.unitName}</div>
                    </div>
                    {(unreadCounts[unit.id]) && (
                        <Badge bg="danger" pill>{unreadCounts[unit.id]}</Badge>
                    )}
                </ListGroup.Item>
            ));
    };

    return (
        <Container>
            <ListGroup className="unit-list">
                {renderUnitList()}
            </ListGroup>
        </Container>
    );
}

export default UnitSelector;
