import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import UnitSelector from './UnitSelector';
import ChatRoom from './ChatRoom';
import './Chat.css';

function Chat() {
    const [selectedUnit, setSelectedUnit] = useState(null);

    const handleSelectUnit = (unit) => {
        setSelectedUnit(unit);
    };

    const handleBack = () => {
        setSelectedUnit(null);
    };

    return (
        <div className="full-bg">
            <Container className="chat-container mt-3">
                <Row className="justify-content-between align-items-center mb-3">
                    <Col>
                        <h1 className="chat-title">Klepetalnica</h1>
                    </Col>
                    <Col className="text-end">
                        {selectedUnit && (
                            <Button variant="secondary" onClick={handleBack}>Nazaj na izbiro</Button>
                        )}
                    </Col>
                </Row>
                {selectedUnit ? (
                    <ChatRoom unitId={selectedUnit.id} unitName={selectedUnit.unitName} />
                ) : (
                    <UnitSelector onSelectUnit={handleSelectUnit} />
                )}
            </Container>
        </div>
    );
}

export default Chat;
