class User {
  constructor({ uid, firstName, lastName, displayName, email, role, photoURL, unit }) {
    this.uid = uid || null;
    this.firstName = firstName || "";
    this.lastName = lastName || "";
    this.displayName = displayName || `${firstName} ${lastName}`;
    this.email = email || ""; // Ensure email is handled properly
    this.role = role || "user";
    this.photoURL = photoURL || "path_to_default_profile_image.png";
    this.unit = unit || "";
  }

  // Method to convert User instance to Firestore format
  toFirestore() {
    return {
      uid: this.uid,
      firstName: this.firstName,
      lastName: this.lastName,
      displayName: this.displayName,
      email: this.email, // Include email in Firestore
      role: this.role,
      photoURL: this.photoURL,
      unit: this.unit,
    };
  }

  // Method to create User instance from Firestore data
  static fromFirestore(snapshot) {
    const data = snapshot.data();
    return new User({
      uid: snapshot.id,
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: data.displayName,
      email: data.email, // Retrieve email from Firestore data
      role: data.role,
      photoURL: data.photoURL,
      unit: data.unit,
    });
  }
}

export default User;
