// src/models/QnAMessage.js
class QnAMessage {
    constructor({ id, text, uid, displayName, email, createdAt }) {
      this.id = id || null;
      this.text = text || "";
      this.uid = uid || null;
      this.displayName = displayName || "Anonymous";
      this.email = email || "";
      this.createdAt = createdAt || new Date();
    }
  
    // Convert to Firestore format
    toFirestore() {
      return {
        text: this.text,
        uid: this.uid,
        displayName: this.displayName,
        email: this.email,
        createdAt: this.createdAt
      };
    }
  
    // Create instance from Firestore document
    static fromFirestore(snapshot) {
      const data = snapshot.data();
      return new QnAMessage({
        id: snapshot.id,
        text: data.text,
        uid: data.uid,
        displayName: data.displayName,
        email: data.email,
        createdAt: data.createdAt ? data.createdAt.toDate() : null
      });
    }
  }
  
  export default QnAMessage;
  