import React, { useState, Suspense, lazy } from 'react';
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import './Gallery.css';
import { useUser } from '../../context/UserContext';

const GalleryCreate = lazy(() => import('./GalleryCreate'));
const GalleryList = lazy(() => import('./GalleryList'));
const GalleryDetail = lazy(() => import('./GalleryDetail'));

function Gallery() {
    const [selectedGallery, setSelectedGallery] = useState(null);
    const [showCreateGallery, setShowCreateGallery] = useState(false);
  
    const { user } = useUser();  // Get the current user from context
    const canCreateGallery = user?.role === 'admin' || user?.role === 'local-admin';  // Check if the user can create a gallery

    const handleSelectGallery = (gallery) => {
        setSelectedGallery(gallery);
        setShowCreateGallery(false);
    };

    const handleBack = () => {
        setSelectedGallery(null);
        setShowCreateGallery(false);
    };

    const toggleCreateGalleryForm = () => {
        setShowCreateGallery(!showCreateGallery);
        setSelectedGallery(null);
    };

    const handleGalleryCreated = () => {
        setShowCreateGallery(false);
        setSelectedGallery(null);
    };

    return (
        <div className="full-bg">
            <Container className="gallery-container mt-3">
                <Row className="justify-content-between align-items-center mb-3">
                    <Col>
                        <h1 className="gallery-title">Galerija</h1>
                    </Col>
                    <Col className="text-end">
                        {canCreateGallery && !selectedGallery && (
                            <Button variant={showCreateGallery ? "secondary" : "danger"} onClick={toggleCreateGalleryForm}>
                                {showCreateGallery ? 'Nazaj na izbiro' : 'Ustvari galerijo'}
                            </Button>
                        )}
                        {selectedGallery && (
                            <Button variant="secondary" onClick={handleBack}>Nazaj na izbiro</Button>
                        )}
                    </Col>
                </Row>
                <Suspense fallback={<Spinner animation="border" />}>
                    {!selectedGallery ? (
                        showCreateGallery ? (
                            <GalleryCreate onGalleryCreated={handleGalleryCreated} />
                        ) : (
                            <GalleryList onSelectGallery={handleSelectGallery} isAdmin={canCreateGallery} />
                        )
                    ) : (
                        <GalleryDetail gallery={selectedGallery} />
                    )}
                </Suspense>
            </Container>
        </div>
    );
}

export default Gallery;
