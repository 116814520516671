import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Badge } from 'react-bootstrap';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, onSnapshot, query, where, orderBy, collection } from 'firebase/firestore';
import { auth, db } from "../../firebase/firebase-config";
import { signOut } from "firebase/auth";
import './Dashboard.css';

function Dashboard() {
  const [profileImage, setProfileImage] = useState("path_to_default_profile_image.png");
  const [hasUnreadChats, setHasUnreadChats] = useState(false);
  const [hasUnreadQnA, setHasUnreadQnA] = useState(false);
  const [hasUnrespondedPolls, setHasUnrespondedPolls] = useState(false);
  const [hasUnreadNotices, setHasUnreadNotices] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileImage = async () => {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().photoURL) {
        setProfileImage(docSnap.data().photoURL);
      }
    };

    fetchProfileImage();
  }, []);

  useEffect(() => {
    const fetchUnreadChats = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data().lastRead?.chat || {};

      const unsubscribes = [];

      const handleSnapshot = (unitId) => (querySnapshot) => {
        const hasUnread = querySnapshot.docs.some(doc => doc.data().uid !== auth.currentUser.uid);
        if (hasUnread) {
          setHasUnreadChats(true);
        }
      };

      Object.keys(lastReadTimestamps).forEach(unitId => {
        const q = query(
          collection(db, 'messages'),
          where('unit', '==', unitId),
          where('createdAt', '>', lastReadTimestamps[unitId])
        );

        const unsubscribe = onSnapshot(q, handleSnapshot(unitId));
        unsubscribes.push(unsubscribe);
      });

      return () => {
        unsubscribes.forEach(unsubscribe => unsubscribe());
      };
    };

    const fetchUnreadQnACount = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data().lastRead?.qna || {};

      const q = query(collection(db, 'questions'));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let hasUnread = false;

        querySnapshot.forEach(async (doc) => {
          const questionId = doc.id;
          const messagesCollectionRef = collection(db, 'questions', questionId, 'messages');
          const lastReadTimestamp = lastReadTimestamps[questionId] || 0;
          const messageQuery = query(messagesCollectionRef, where('createdAt', '>', lastReadTimestamp));

          onSnapshot(messageQuery, (messageSnapshot) => {
            const unreadCount = messageSnapshot.docs.filter(doc => doc.data().uid !== auth.currentUser.uid).length;
            if (unreadCount > 0) {
              hasUnread = true;
              setHasUnreadQnA(true);
            }
          });
        });

        if (!hasUnread) {
          setHasUnreadQnA(false);
        }
      });

      return () => unsubscribe();
    };

    const fetchUnrespondedPollCount = async () => {
      const q = query(collection(db, 'polls'), where('expires', '>', new Date()));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        let hasUnresponded = false;

        await Promise.all(querySnapshot.docs.map(async (doc) => {
          const pollId = doc.id;
          const answersRef = collection(db, 'polls', pollId, 'answers');
          const answersSnapshot = await getDocs(answersRef);
          const hasResponded = answersSnapshot.docs.some(answerDoc => answerDoc.data().userId === auth.currentUser.uid);

          if (!hasResponded) {
            hasUnresponded = true;
            setHasUnrespondedPolls(true);
          }
        }));

        if (!hasUnresponded) {
          setHasUnrespondedPolls(false);
        }
      });

      return () => unsubscribe();
    };

    const fetchUnreadNotices = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data().lastRead?.notices || {};

      const q = query(collection(db, 'notices'), orderBy('createdAt', 'desc'));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let hasUnread = false;

        querySnapshot.forEach((doc) => {
          const noticeId = doc.id;
          const createdAt = doc.data().createdAt;
          if (!lastReadTimestamps[noticeId] || lastReadTimestamps[noticeId].toMillis() < createdAt.toMillis()) {
            hasUnread = true;
            setHasUnreadNotices(true);
          }
        });

        if (!hasUnread) {
          setHasUnreadNotices(false);
        }
      });

      return () => unsubscribe();
    };

    fetchUnreadChats();
    fetchUnreadQnACount();
    fetchUnrespondedPollCount();
    fetchUnreadNotices();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  return (
    <div className="dashboard-container">
      <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" className="custom-navbar">
        <Container>
          <Navbar.Brand as={NavLink} to="/" className="brand">ZSSS, Zav. Triglav</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="chat">
                Klepetalnica {hasUnreadChats && <Badge bg="danger" pill>&#8226;</Badge>}
              </Nav.Link>
              <Nav.Link as={NavLink} to="qna">
                Q&A {hasUnreadQnA && <Badge bg="danger" pill>&#8226;</Badge>}
              </Nav.Link>
              <Nav.Link as={NavLink} to="polls">
                Ankete {hasUnrespondedPolls && <Badge bg="danger" pill>&#8226;</Badge>}
              </Nav.Link>
              <Nav.Link as={NavLink} to="notices">
                Obvestila {hasUnreadNotices && <Badge bg="danger" pill>&#8226;</Badge>}
              </Nav.Link>
              <Nav.Link as={NavLink} to="gallery">
                Galerija
              </Nav.Link>
              <Nav.Link as={NavLink} to="documents">
                Dokumenti
              </Nav.Link>
              <Nav.Link as={NavLink} to="sindikalist">
                Sindikalist
              </Nav.Link>
              <Nav.Link as={NavLink} to="article">
                Članki
              </Nav.Link>
              <Nav.Link as={NavLink} to="benefits">
                Ugodnosti
              </Nav.Link>
            </Nav>
            <Nav>
              <NavDropdown title={<img src={profileImage} alt="profile" className="profile-picture" />} id="collasible-nav-dropdown">
                <NavDropdown.Item as={NavLink} to="user-management">Upravljanje uporabnikov</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="profile">Profil</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout}>Odjava</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Outlet />

      <footer className="footer mt-auto py-3 bg-light">
        <Container>
          <span>© 2024 Konferenca sindikatov zavarovalništva Slovenije - All Rights Reserved</span>
        </Container>
      </footer>
    </div>
  );
}

export default Dashboard;
