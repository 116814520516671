import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import PollCreateForm from './PollCreateForm';
import PollsList from './PollsList';
import PollDetails from './PollDetails';
import { deleteDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../firebase/firebase-config';  // Import db here
import { useUser } from '../../context/UserContext';  // Import useUser to access user details
import './Polls.css';

function Polls() {
    const { user, loading } = useUser();  // Access user and loading states from UserContext
    const [selectedPoll, setSelectedPoll] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [pollToEdit, setPollToEdit] = useState(null);

    const toggleCreateForm = () => {
        setShowCreateForm(!showCreateForm);
        setSelectedPoll(null);
        setPollToEdit(null);
    };

    const showBackToList = () => {
        setShowCreateForm(false);
        setSelectedPoll(null);
        setPollToEdit(null);
    };

    const handlePollCreated = () => {
        setShowCreateForm(false);
        setSelectedPoll(null);
        setPollToEdit(null);
    };

    const handleEditPoll = (poll) => {
        setPollToEdit(poll);
        setShowCreateForm(true);
    };

    const handleDeletePoll = async (pollId) => {
        try {
            await deleteDoc(doc(db, "polls", pollId));
            alert('Poll deleted successfully');
            showBackToList();
        } catch (error) {
            console.error('Error deleting poll:', error);
            alert('Failed to delete poll. Please try again.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="full-bg">
            <Container className="mt-3 polls-container">
                <Row className="justify-content-between align-items-center mb-3">
                    <Col>
                        <h1 className='polls-title'>Ankete</h1>
                    </Col>
                    <Col className="text-end">
                        {(showCreateForm || selectedPoll) && (
                            <Button onClick={showBackToList} variant="secondary">
                                Nazaj na izbiro
                            </Button>
                        )}
                        {(user.role === 'admin' || user.role === 'local-admin') && !showCreateForm && !selectedPoll && (
                            <Button onClick={toggleCreateForm} variant="danger">
                                Ustvari anketo
                            </Button>
                        )}
                    </Col>
                </Row>
                {showCreateForm ? (
                    <PollCreateForm onPollCreated={handlePollCreated} pollToEdit={pollToEdit} />
                ) : selectedPoll ? (
                    <PollDetails poll={selectedPoll} isAdmin={user.role === 'admin'} onEditPoll={handleEditPoll} onBackToList={showBackToList} />
                ) : (
                    <PollsList onSelectPoll={setSelectedPoll} onEditPoll={handleEditPoll} isAdmin={user.role === 'admin'} onDeletePoll={handleDeletePoll} />
                )}
            </Container>
        </div>
    );
}

export default Polls;
